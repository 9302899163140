import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import useMountedEffect from 'hooks/useMountedEffect'
import { clamp } from 'lib/maths/mathUtils'
import RangeInput from './RangeInput'
import Group from 'components/utils/Group'

const SliderContainer = styled.div`
  height: ${rem(24)};
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
`

const MinRange = styled(RangeInput)`
  right: ${rem(24)};
  width: calc(100% - ${rem(24)});
`

const MaxRange = styled(RangeInput)`
  left: ${rem(24)};
  width: calc(100% - ${rem(24)});
`

const TrackCap = styled.div`
  width: ${rem(24)};
  height: ${rem(6)};
  background-color: ${props => props.theme.palette.neutral.default.five};

  &.left {
    border-top-left-radius: ${props => props.theme.borderRadius.round};
    border-bottom-left-radius: ${props => props.theme.borderRadius.round};
  }

  &.right {
    border-top-right-radius: ${props => props.theme.borderRadius.round};
    border-bottom-right-radius: ${props => props.theme.borderRadius.round};
  }
`

const ProgressTrack = styled.div`
  flex-grow: 1;
  height: ${rem(6)};
  background: linear-gradient(
    to right,
    ${props => props.theme.palette.neutral.default.five} var(--start),
    ${props => props.theme.palette.brand.primary.normal} var(--start, 0%) var(--end, 100%),
    ${props => props.theme.palette.neutral.default.five} var(--end, 100%)
  );
`

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  min: number;
  max: number;
  defaultStartValue?: number;
  defaultEndValue?: number;
  startValue?: number;
  endValue?: number;
  onStartChange?: React.ChangeEventHandler<HTMLInputElement>;
  onEndChange?: React.ChangeEventHandler<HTMLInputElement>;
}

function DualSlider(props: Props) {
  const {
    defaultStartValue,
    defaultEndValue,
    startValue,
    endValue,
    min,
    max,
    name,
    id,
    className,
    onStartChange,
    onEndChange,
    onChange,
    onReset,
    ...inputProps
  } = props

  const progressTrackRef = useRef<HTMLDivElement | null>(null)
  const startRangeRef = useRef<HTMLInputElement | null>(null)
  const endRangeRef = useRef<HTMLInputElement | null>(null)

  const updateProgressTrack = (start: number, end: number) => {
    const totalRange = max - min
    const adjustedStartValue = start - min
    const adjustedEndValue = end - min

    if (progressTrackRef.current) {
      progressTrackRef.current.style.setProperty('--start', `${(adjustedStartValue / totalRange) * 100}%`)
      progressTrackRef.current.style.setProperty('--end', `${(adjustedEndValue / totalRange) * 100}%`)
    }
  }

  useMountedEffect(() => {
    if (startRangeRef.current && endRangeRef.current) {
      // If min/max changes, make sure our values are still valid and within the ranges
      const nextStart = clamp(startRangeRef.current.valueAsNumber, min, max)
      const nextEnd = clamp(endRangeRef.current.valueAsNumber, min, max)
      if (nextStart !== startRangeRef.current.valueAsNumber) {
        startRangeRef.current.value = nextStart.toString()
      }
      if (nextEnd !== endRangeRef.current.valueAsNumber) {
        endRangeRef.current.value = nextEnd.toString()
      }
      updateProgressTrack(nextStart, nextEnd)
    }
  }, [min, max])

  useEffect(() => {
    if (startRangeRef.current && endRangeRef.current) {
      updateProgressTrack(
        startValue ?? startRangeRef.current.valueAsNumber,
        endValue ?? endRangeRef.current.valueAsNumber,
      )
    }
  // eslint-disable-next-line
  }, [startValue, endValue])

  return <SliderContainer className={className}>
    <Group direction="horizontal" fullWidth>
      <TrackCap className="left" />
      <ProgressTrack ref={progressTrackRef} />
      <TrackCap className="right" />
    </Group>
    <MinRange
      {...inputProps}
      ref={startRangeRef}
      onChange={(e) => {
        if (endRangeRef.current) {
          const end = endRangeRef.current.valueAsNumber
          if (e.currentTarget.valueAsNumber <= end) {
            updateProgressTrack(e.currentTarget.valueAsNumber, end)
            onStartChange?.(e)
          } else {
          // can't go above our other range, we have to maintain this manually
            e.preventDefault()
            e.currentTarget.value = end.toString()
          }
        }
      }}
      min={min}
      max={max}
      defaultValue={defaultStartValue ?? min}
      value={startValue}
      name={`${name}.start::number`}
    />
    <MaxRange
      {...inputProps}
      ref={endRangeRef}
      onChange={(e) => {
        if (startRangeRef.current) {
          const start = startRangeRef.current.valueAsNumber
          if (e.currentTarget.valueAsNumber >= start) {
            updateProgressTrack(start, e.currentTarget.valueAsNumber)
            onEndChange?.(e)
          } else {
          // can't go below our other range, we have to maintain this manually
            e.preventDefault()
            e.currentTarget.value = start.toString()
          }
        }
      }}
      defaultValue={defaultEndValue ?? max}
      value={endValue}
      min={min}
      max={max}
      name={`${name}.end::number`}
    />
  </SliderContainer>
}

export default React.memo(DualSlider)
