import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Caption from '../Typography/Caption'
import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import FormatCurrency from 'components/Common/FormatCurrency'
import DualSlider from './DualSlider'
import Group from 'components/utils/Group'

const Container = styled(VerticalSpacer)`
  position: relative;
  width: 100%;
`

interface Props extends React.ComponentProps<typeof DualSlider> {
  variant: 'label' | 'input';
  format?: 'currency' | 'none' | 'custom';
  unit?: string;
  inputStep?: number;
  formatValue?: (value: number) => string | number
}

function DualSliderInput(props: Props) {
  const {
    variant,
    format = 'none',
    onInput,
    onStartChange,
    onEndChange,
    unit,
    startValue: controlledStartValue,
    endValue: controlledEndValue,
    defaultStartValue: uncontrolledStartValue,
    defaultEndValue: uncontrolledEndValue,
    formatValue,
    ...sliderProps
  } = props
  const [startValue, setStartValue] = useState<number | undefined>(() => controlledStartValue ?? uncontrolledStartValue ?? sliderProps.min)
  const [inputStartValue, setInputStartValue] = useState<number | undefined>(() => controlledStartValue ?? uncontrolledStartValue ?? sliderProps.min)
  const [endValue, setEndValue] = useState<number | undefined>(() => controlledEndValue ?? uncontrolledEndValue ?? sliderProps.max)
  const [inputEndValue, setInputEndValue] = useState<number | undefined>(() => controlledEndValue ?? uncontrolledEndValue ?? sliderProps.max)

  useEffect(() => {
    setStartValue(controlledStartValue ?? uncontrolledStartValue ?? sliderProps.min)
    setInputStartValue(controlledStartValue ?? uncontrolledStartValue ?? sliderProps.min)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledStartValue])

  useEffect(() => {
    setEndValue(controlledEndValue ?? uncontrolledEndValue ?? sliderProps.max)
    setInputEndValue(controlledEndValue ?? uncontrolledEndValue ?? sliderProps.max)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledEndValue])

  const onSliderStartChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    onStartChange?.(event)
    setStartValue(event.currentTarget.valueAsNumber)
    setInputStartValue(event.currentTarget.valueAsNumber)
  }, [onStartChange])

  const onSliderEndChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    onEndChange?.(event)
    setEndValue(event.currentTarget.valueAsNumber)
    setInputEndValue(event.currentTarget.valueAsNumber)
  }, [onEndChange])

  const onInputStartChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const value = event.currentTarget.valueAsNumber

    if (event.currentTarget.validity.valid && !isNaN(value)) {
      onStartChange?.(event)
      setStartValue(value)
    }

    setInputStartValue(value)
  }, [onStartChange])

  const onInputEndChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const value = event.currentTarget.valueAsNumber

    if (event.currentTarget.validity.valid && !isNaN(value)) {
      onEndChange?.(event)
      setEndValue(value)
    }

    setInputEndValue(value)
  }, [onEndChange])

  return (
    <Container gap={variant === 'label' ? 4 : 16}>
      <DualSlider
        {...sliderProps}
        defaultStartValue={uncontrolledStartValue}
        defaultEndValue={uncontrolledEndValue}
        startValue={startValue}
        endValue={endValue}
        onStartChange={onSliderStartChange}
        onEndChange={onSliderEndChange}
      />
      {variant === 'label' && <Group direction="horizontal" gap={8} horizontalAlign="space-between">
        <Caption variant="large" colour="neutral-three">
          {startValue !== undefined && <>
            {format === 'currency' && <FormatCurrency value={startValue} />}
            {format === 'custom' && <>{formatValue?.(startValue)}</>}
            {format === 'none' && <>{startValue}</>}
            {unit}
          </>}
        </Caption>
        <Caption variant="large" colour="neutral-three">
          {endValue !== undefined && <>
            {format === 'currency' && <FormatCurrency value={endValue} />}
            {format === 'custom' && <>{formatValue?.(endValue)}</>}
            {format === 'none' && <>{endValue}</>}
            {unit}
          </>}
        </Caption>
      </Group>}
      {variant === 'input' && <Group direction="horizontal" gap={8} horizontalAlign="stretch">
        <TextInputOnly value={inputStartValue} type="number" onChange={onInputStartChange} step={sliderProps.inputStep} min={sliderProps.min} max={endValue} noValidationSpacing />
        <TextInputOnly value={inputEndValue} type="number" onChange={onInputEndChange} step={sliderProps.inputStep} min={startValue} max={sliderProps.max} noValidationSpacing />
      </Group>}
    </Container>
  )
}

export default DualSliderInput
